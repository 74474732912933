@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Montserrat";
  src: url(../public/assets/fonts/Montserrat-VariableFont_wght.ttf);
}

@font-face {
  font-family: "Inter";
  src: url(../public/assets/fonts/Inter-VariableFont_slnt-wght.ttf);
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.css-18i3v7t {
  margin: 0 !important;
}

.f * {
  font-family: Montserrat, sans-serif;
}

body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.ql-picker.ql-font .ql-picker-label[data-value="Montserrat"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Montserrat"]::before {
  font-family: "Montserrat", cursive;
  content: "Montserrat" !important;
}

.ql-font-Montserrat {
  font-family: "Montserrat";
}
.swiper-button-next {
  display: none !important;
}

.swiper-button-prev {
  display: none !important;
}
.header-text {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0em;
}

@layer utilities {
  @variants responsive {
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
}
