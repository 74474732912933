.animated-buttons {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 36px;
  border: 4px solid;
  border-color: transparent;
  font-size: 16px;
  background-color: inherit;
  border-radius: 100px;
  font-weight: 600;
  color: #39a0cc;
  box-shadow: 0 0 0 2px #39a0cc;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-buttons svg {
  position: absolute;
  width: 24px;
  fill: #39a0cc;
  z-index: 9;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-buttons .arr-1 {
  right: 16px;
}

.animated-buttons .arr-2 {
  left: -25%;
}

.animated-buttons .circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-color: #39a0cc;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-buttons .text {
  position: relative;
  z-index: 1;
  transform: translateX(-12px);
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-buttons:hover {
  box-shadow: 0 0 0 12px transparent;
  color: #fff;
  border-radius: 100px;
}

.animated-buttons:hover .arr-1 {
  right: -25%;
}

.animated-buttons:hover .arr-2 {
  left: 16px;
}

.animated-buttons:hover .text {
  transform: translateX(12px);
}

.animated-buttons:hover svg {
  fill: #fff;
}

.animated-buttons:active {
  scale: 0.95;
  box-shadow: 0 0 0 4px #39a0cc;
}

.animated-buttons:hover .circle {
  width: 820px;
  height: 220px;
  opacity: 1;
}

.animated-buttons2 {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 5px 36px;
  border: 4px solid;
  border-color: transparent;
  font-size: 16px;
  background-color: inherit;
  border-radius: 100px;
  font-weight: 600;
  color: #39a0cc;
  box-shadow: 0 0 0 2px #39a0cc;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}
