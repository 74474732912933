.react-player__preview {
  border-radius: 16px;
}

.rounded-2xl video {
  object-fit: cover;
  border-radius: 16px;
}

.react-player__shadow {
  display: none !important;
}
