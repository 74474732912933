.slick-dots1 {
  display: flex !important;
  justify-content: center;
  margin-top: 10px;
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 10px;
}

.slick-dots1 li {
  display: inline-block;
  margin: 0 5px;
}

.slick-dots1 li button {
  padding: 0;
}

.slick-dots1 li button::before {
  font-size: 0; /* Убираем стандартные точки */
}

.slick-thumb1 {
  display: flex;
  justify-content: center;
}

.slick-thumb1 img {
  border: 2px solid transparent;
  border-radius: 8px;
  transition: border-color 0.3s ease;
  width: 140px;
}

.slick-thumb1 .slick-active img {
  border-color: #3490dc; /* Цвет рамки для активного слайда */
}

.slick-dots1::-webkit-scrollbar {
  display: none;
}
