.cselect select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font: normal 11px/22px Arial, Sans-Serif;
  color: black;
  border: 1px solid #ccc;
}
.styledSelect:active,
.styledSelect.active {
  background-color: #eee;
}
option {
  background-color: blueviolet;
}

.MuiSelect-select {
  padding-bottom: 0 !important;
}
