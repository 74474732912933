.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 300px;
  width: 400px;
  position: absolute;
}

.play-btn-box {
  border: 2px rgba(255, 255, 255, 0.11) solid;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.play-btn-line1 {
  border: 2px rgba(255, 255, 255, 0.451) solid;
  border-radius: 50%;
  width: 160px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right-color: transparent;
}
.play-btn-line2 {
  border: 2px rgba(255, 255, 255, 0.451) solid;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right-color: transparent;
}
.play-button {
  color: black;
  background-color: rgb(212, 212, 212);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#line1 {
  -webkit-animation: rotation 4s infinite linear;
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

#line2 {
  -webkit-animation: rotation1 3s infinite linear;
}

@keyframes rotation1 {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@media screen and (max-width: 500px) {
  .css-1fu2e3p-MuiPaper-root-MuiDialog-paper {
    margin: 5px !important;
  }
}

