.slick-dots {
  display: flex !important;
  justify-content: center;
  margin-top: 40px;
  bottom: -37px !important;
  position: relative !important;
}

.custom-dot {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 12px;
  margin: 0 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.custom-dot .dot {
  width: 12px;
  height: 12px;
  background-color: #d1d5db;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.custom-dot.active .dot {
  width: 100%;
  height: 100%;
  background-color: #0284c7;
  border-radius: 25px;
}
